import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Project({ data }) {
  const post = data.markdownRemark
  console.log('data', data)
  return (
    <Layout>
      <SEO title="Tom Price" />
      <div className="jumbotron jumbotron-fluid p-0">
        {post.frontmatter.bannerImage ? 
          <Img fluid={post.frontmatter.bannerImage.childImageSharp.fluid} alt={`${post.frontmatter.title} banner`} /> :
          <img src="./images/code-banner.png"/>
        }
      </div>

      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1>{post.frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        bannerImage {
          childImageSharp {
            fluid(maxHeight: 360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    file(relativePath: { eq: "../images/code-banner.png" }) {
      childImageSharp {
        fluid(maxHeight: 360) {
          ...GatsbyImageSharpFluid
        }
      }
    }

  }
`
